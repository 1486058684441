import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import {client} from 'scrumship-client';
import {BrowserRouter} from "react-router-dom";

import appConfig from "./config/applicationConfiguration";

axios.defaults.baseURL = appConfig.apiUrl;
axios.defaults.headers['Accept'] = 'application/json';
client.log('lXruWYU');

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
