import axios from "axios";

export const savePhoto = (formData, callback) => {

    const config = {
        url: '/photos',
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    axios.request(config).then(response => callback(response.data));
};

export const loadPhoto = (id, callback) => {
    const config = {
        url: `/photos/${id}`,
    };
    axios.request(config).then(response => callback(response.data));
};

