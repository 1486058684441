import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useFormik, Form, FormikProvider} from "formik";
import * as Yup from "yup"
import TextInput from '../../components/inputs/TextInput.js';
import PhotoInput from '../../components/inputs/PhotoInput';

import Button from '../../components/buttons/Button.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import './Category.scss'

import * as actions from './CategoriesActions.js';

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required")
});

const Category = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [previousName, setPreviousName] = useState('');
    const [imageId, setImageId] = useState(null);
    const [initialState, setInitialState] = useState({
        id: null,
        name: "",
        photo_id: null
    });

    useEffect(() => {
        if (id) {
            actions.loadCategory(id, data => {
                setInitialState({
                    id: data.id,
                    name: data.name,
                    photo_id: data.image ? data.image.id : null
                })
                setPreviousName(data.name);
            });
        }
    }, [id]);

    const handleSubmit = (values) => {
        const valuesUpdate = {id: id, ...values, photo_id: imageId};
        actions.saveCategory(valuesUpdate, () => navigate('/categories'));
    };

    const formik = useFormik({
        initialValues: initialState,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    return (
        <div className='category'>
            <header className='header'>
                <div className='title'>
                    <FontAwesomeIcon icon={faBars} className='icon'/>
                    <div className='path'>
                        <h1>Categories</h1>
                        <FontAwesomeIcon icon={faChevronRight} className='icon'/>
                        <h2>{id ? `Update - ${previousName}` : "New"}</h2>
                    </div>
                </div>
                <Button variant="primary" name="Save" onClick={() => formik.handleSubmit()}/>
            </header>
            <main className='main'>
                <FormikProvider value={formik}>
                    <Form id="categoryForm" className="form">
                        <TextInput isRequired={true} name="name" touched={formik.touched} errors={formik.errors}
                                   resetForm={formik.resetForm}/>
                        <PhotoInput photoId={initialState.photo_id} onImageUpload={setImageId}/>
                    </Form>
                </FormikProvider>
            </main>
        </div>
    );
}

export default Category;