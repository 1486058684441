import axios from "axios";

export const loadCategories = (callback) => {
    const config = {
        url: '/categories',
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};

export const loadCategory = (id, callback) => {
    const config = {
        url: `/categories/${id}`,
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};

export const saveCategory = (resource, callback) => {
    const config = {
        url: resource.id ? `/categories/${resource.id}` : '/categories',
        method: resource.id ? 'PUT' : 'POST',
        data: {category: resource},
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};

export const deleteCategory = (id, callback) => {
    const config = {
        url: `/categories/${id}`,
        method: 'DELETE',
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};