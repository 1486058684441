import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useFormik, Form, FormikProvider} from "formik";
import * as Yup from "yup"
import TextInput from '../../components/inputs/TextInput.js';
import PhotoInput from '../../components/inputs/PhotoInput';
import SelectInput from '../../components/inputs/SelectInput';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCarrot, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import './Ingredient.scss'

import * as actions from './IngredientsActions.js';
import Button from "../../components/buttons/Button";

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required")
});

const Ingredient = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [previousName, setPreviousName] = useState('');
    const [imageId, setImageId] = useState(null);
    const [initialState, setInitialState] = useState({
        id: null,
        name: "",
        unit: "Litters",
        photo_id: null
    });

    const options = [
        {value: "Litters", label: "Litters"},
        {value: "Grams", label: "Grams"},
        {value: "Quantity", label: "Quantity"}
    ];

    useEffect(() => {
        if (id) {
            actions.loadIngredient(id, data => {
                setInitialState({
                    id: data.id,
                    name: data.name,
                    unit: data.unit,
                    photo_id: data.image ? data.image.id : null
                })
                setPreviousName(data.name);
            });
        }
    }, [id]);

    const handleSubmit = (values) => {
        const valuesUpdate = {id: id, ...values, photo_id: imageId};
        actions.saveIngredient(valuesUpdate, () => navigate('/ingredients'));
    };

    const formik = useFormik({
        initialValues: initialState,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    return (
        <div className='ingredient'>
            <header className='header'>
                <div className='title'>
                    <FontAwesomeIcon icon={faCarrot} className='icon'/>
                    <div className='path'>
                        <h1>Ingredients</h1>
                        <FontAwesomeIcon icon={faChevronRight} className='icon'/>
                        <h2>{id ? `Update - ${previousName}` : "New"}</h2>
                    </div>
                </div>
                <Button variant="primary" name="Save" onClick={() => formik.handleSubmit()}/>
            </header>
            <main className='main'>
                <FormikProvider value={formik}>
                    <Form id="ingredientForm" className="form">
                        <TextInput isRequired={true} name="name" touched={formik.touched} errors={formik.errors}
                                   resetForm={formik.resetForm}/>
                        <SelectInput isRequired={true} name="unit" options={options}/>
                        <PhotoInput photoId={initialState.photo_id} onImageUpload={setImageId}/>
                    </Form>
                </FormikProvider>
            </main>
        </div>
    );
}

export default Ingredient;