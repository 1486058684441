import axios from "axios";

export const loadIngredients = (callback) => {
    const config = {
        url: '/ingredients',
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};

export const loadIngredient = (id, callback) => {
    const config = {
        url: `/ingredients/${id}`,
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};

export const saveIngredient = (resource, callback) => {
    const config = {
        url:  resource.id ? `/ingredients/${resource.id}`: '/ingredients',
        method: resource.id ? 'PUT' : 'POST',
        data: { ingredient: resource },
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};

export const deleteIngredient = (id, callback) => {
    const config = {
        url: `/ingredients/${id}`,
        method: 'DELETE',
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};