import React from 'react';
import Modal from 'react-modal';

import Button from '../buttons/Button';
import './ItemModal.scss'

const ItemModal = ({item, type, onRemoveItem, onClose, isModalOpen}) => {
    Modal.setAppElement('#root');

    const getFileNameFromUrl = (url) => {
        const parts = url.replace(/%20/g, ' ').split('/');
        return parts[parts.length - 1];
    };

    const removeItem = () => {
        onRemoveItem(item.id);
    };

    return (
        <Modal isOpen={item?.name || isModalOpen} onRequestClose={onClose} contentLabel="Confirm Remove"
               className="item-modal modal" overlayClassName="item-modal overlay">
            <header className='path'>
                <h1>Delete</h1>
                <h2>&gt; {item?.name}{item?.image_url ? getFileNameFromUrl(item?.image_url) : ''}</h2>
            </header>
            <header className='info'>
                <h3>Are you sure that you want to
                    delete {type}: <div>{item?.name}{item?.image_url ? getFileNameFromUrl(item?.image_url) : ''}</div>?
                </h3>
            </header>
            <div className="buttons">
                <Button variant="secondary" name="Cancel" onClick={onClose}/>
                <Button variant="primary" name="Delete" onClick={removeItem}/>
            </div>
        </Modal>
    );
}

export default ItemModal;