import axios from "axios";

export const loadTags = (callback) => {
    const config = {
        url: '/tags',
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};

export const loadTag = (id, callback) => {
    const config = {
        url: `/tags/${id}`,
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};

export const saveTag = (resource, callback) => {
    console.log(resource);
    const config = {
        url: resource.id ? `/tags/${resource.id}` : '/tags',
        method: resource.id ? 'PUT' : 'POST',
        data: {tag: resource},
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};

export const deleteTag = (id, callback) => {
    const config = {
        url: `/tags/${id}`,
        method: 'DELETE',
    };
    axios.request(config).then(
        response => callback(response.data)
    );
};