import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Button from '../../components/buttons/Button';
import './Tags.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTag} from '@fortawesome/free-solid-svg-icons';
import ItemModal from '../../components/modals/ItemModal';
import ItemTable from '../../components/tables/ItemTable';

import * as actions from './TagsActions';
import Options from "../../components/buttons/Options";

const Tags = () => {
    const [tags, setTags] = useState([]);
    const [tag, setTag] = useState({
        id: null,
        name: ""
    });

    useEffect(() => {
        actions.loadTags(setTags);
    }, []);

    const handleRemoveTag = (id) => {
        actions.deleteTag(id, () => {
            setTags(tags.filter(tag => tag.id !== id));
            setTag({id: null, name: ""});
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Tag",
                accessor: "name",
                Cell: ({row}) => (
                    <div className='name-cell'>
                        <p className='name'>{row.original.name}</p>
                    </div>
                ),
            },
            {
                Header: 'Options',
                accessor: 'options',
                Cell: ({row}) => (
                    <Options item={row.original} link="tag" deleteItem={(i) => setTag(i)} />
                ),
            },
        ],
        [tags]
    );

    return (
        <div className='tags'>
            <header className='header'>
                <div className='title'>
                    <FontAwesomeIcon icon={faTag} className='icon'/>
                    <h1>Tags</h1>
                </div>
                <Link to='/tag'>
                    <Button variant="primary" name="+ Add new"/>
                </Link>
            </header>
            <ItemTable items={tags} columns={columns}/>
            <ItemModal item={tag} type="tag" onClose={() => setTag({id: null, name: ""})} onRemoveItem={handleRemoveTag}/>
        </div>
    );
}

export default Tags;