import React from 'react';
import {Link} from 'react-router-dom';

import './Options.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenToSquare, faTrashCan} from '@fortawesome/free-solid-svg-icons';


const Options = ({item, link, deleteItem}) => {

    return (
        <div className='option'>
            <Link to={`/${link}/${item.id}`}>
                <FontAwesomeIcon icon={faPenToSquare} title="Edit" className='edit'/>
            </Link>
            <FontAwesomeIcon onClick={() => deleteItem(item)} icon={faTrashCan} title="Delete" className='delete'/>
        </div>
    );
}

export default Options;