import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import Button from '../../components/buttons/Button';
import './Categories.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';

import * as actions from './CategoriesActions';
import ItemModal from '../../components/modals/ItemModal';
import ItemTable from '../../components/tables/ItemTable';
import Options from "../../components/buttons/Options";

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({
        id: null,
        name: ""
    });

    useEffect(() => {
        actions.loadCategories(setCategories);
    }, []);

    const handleRemoveCategory = (id) => {
        actions.deleteCategory(id, () => {
            setCategories(categories.filter(category => category.id !== id))
            setCategory({id: null, name: ""})
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Category",
                accessor: "name",
                Cell: ({row}) => (
                    <div className='name-cell'>
                        <img className='img'
                             src={row.original.image.image_url ? row.original.image.image_url : "/icon_empty.png"}
                             alt='item'/>
                        <p className='name'>{row.original.name}</p>
                    </div>
                ),
            },
            {
                Header: 'Options',
                accessor: 'options',
                Cell: ({row}) => (
                    <Options item={row.original} link="category" deleteItem={(i) => setCategory(i)} />
                ),
            },
        ],
        [categories]
    );

    return (
        <div className='categories'>
            <header className='header'>
                <div className='title'>
                    <FontAwesomeIcon icon={faBars} className='icon'/>
                    <h1>Categories</h1>
                </div>
                <Link to='/category'>
                    <Button variant="primary" name="+ Add new"/>
                </Link>
            </header>
            <ItemTable items={categories} columns={columns}/>
            <ItemModal item={category} type="category" onRemoveItem={handleRemoveCategory}
                       onClose={() => setCategory({id: null, name: ""})}/>
        </div>
    );
}

export default Categories;