import React from 'react'
import {useField, useFormikContext} from "formik"
import Select from 'react-select'

import './SelectInput.scss'

const SelectInput = ({isRequired, name, options}) => {
    const {setFieldValue} = useFormikContext();
    const [field] = useField(name);

    const handleChange = (selectedOption) => {
        setFieldValue(name, selectedOption ? selectedOption.value : '');
    };

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        control: (provided, state) => ({
            ...provided,
            height: '3rem',
            borderRadius: '0.4rem',
            paddingLeft: '0.3rem',
            borderColor: '#a8a8a8',
            opacity: 0.8,
            boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
            "&:hover": {
                borderColor: "black"
            }
        }),
        option: (provided, state) => ({
            ...provided,
            opacity: 0.65,
            paddingLeft: '1rem',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            backgroundColor: state.isFocused ? '#8f86fc' : state.isSelected ? '#867DF0' : 'white',
        }),
        dropdownIndicator: base => ({
            ...base,
            transform: 'scale(1.3)',
            width: '2.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        })
    };

    return (
        <div className="select-input">
            <div className='label'>
                {isRequired &&
                    <p className='required'>*</p>
                }
                <label htmlFor={name} className='name'>{name.charAt(0).toUpperCase() + name.slice(1)}</label>
            </div>
            <Select
                id={name}
                name={name}
                options={options}
                defaultValue={options[0]}
                value={options ? options.find(option => option.value === field.value) : ''}
                onChange={handleChange}
                className='select'
                styles={customStyles}
            />
        </div>
    );
}

export default SelectInput;