import Template from "./pages/template/Template";
import Categories from "./pages/categories/Categories";
import Category from "./pages/categories/Category";
import Ingredients from "./pages/ingredients/Ingredients";
import Ingredient from "./pages/ingredients/Ingredient";
import Tags from "./pages/tags/Tags";
import Tag from "./pages/tags/Tag";
import {Route, Routes} from 'react-router-dom'
import "./App.scss"

function App() {
    return (
        <div className="App">
            <Template>
                <Routes>
                    <Route path="/categories" element={<Categories/>}/>
                    <Route path="/category" element={<Category/>}/>
                    <Route path="/category/:id?" element={<Category/>}/>
                    <Route path="/ingredients" element={<Ingredients/>}/>
                    <Route path="/ingredient" element={<Ingredient/>}/>
                    <Route path="/ingredient/:id?" element={<Ingredient/>}/>
                    <Route path="/tags" element={<Tags/>}/>
                    <Route path="/tag" element={<Tag/>}/>
                    <Route path="/tag/:id?" element={<Tag/>}/>
                </Routes>
            </Template>
        </div>
    );
}

export default App;
