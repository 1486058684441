import React, {useCallback, useEffect, useState} from 'react';
import * as actions from './PhotoActions';
import {useDropzone} from 'react-dropzone';

import Button from '../buttons/Button';
import './Photo.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShareFromSquare} from '@fortawesome/free-solid-svg-icons';
import ItemModal from '../modals/ItemModal';

const Photo = ({photoId, onImageUpload}) => {
    const [actualPhoto, setActualPhoto] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (photoId) {
            actions.loadPhoto(photoId, data => {
                setActualPhoto(data);
            });
        }
    }, [photoId]);

    const onDrop = useCallback((acceptedFiles) => {
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        if (photoId) {
            formData.append('id', photoId);
        }
        actions.savePhoto(formData, (data) => {
            if (data && data.id) {
                setActualPhoto(data);
                onImageUpload(data.id);
            }
        });
    }, [onImageUpload]);

    const {getRootProps, getInputProps, isDragActive, open} = useDropzone({onDrop, noClick: true});

    const handleRemovePhoto = (e) => {
        setActualPhoto(null);
        setIsModalOpen(false);
        onImageUpload(null);
    };

    return (
        <div className="photo" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="box">
                {actualPhoto ? (
                    <div className='contain'>
                        <img src={actualPhoto.image_url} alt="Uploaded" className="img"/>
                        <button className='remove' onClick={(e) => {
                            e.preventDefault();
                            setIsModalOpen(true);
                        }}>Remove</button>
                    </div>
                ) : isDragActive ? (
                    <div className='drop'>
                        <h1>Drop the file here ...</h1>
                        <p className="max-size">(Max. File size: 30 MB)</p>
                    </div>
                ) : (
                    <div className='upload'>
                        <FontAwesomeIcon icon={faShareFromSquare} className="icon"/>
                        <div className='text'>
                            <p className="click" onClick={open}>Click to Upload</p>
                            <p className="drag-drop">or drag and drop</p>
                        </div>
                        <p className="max-size">(Max. File size: 30 MB)</p>
                    </div>
                )}
            </div>
            <ItemModal item={actualPhoto} type="photo" onRemoveItem={handleRemovePhoto} isModalOpen={isModalOpen}
                       onClose={() => setIsModalOpen(false)}/>
        </div>
    );
};

export default Photo;