import React from 'react'
import {Field, ErrorMessage} from "formik"
import classNames from 'classnames';

import './TextInput.scss'

const TextInput = ({isRequired, name, touched, errors, resetForm}) => {

    return (
        <div className="text-input">
            <div className='label'>
                {isRequired &&
                    <p className='required'>*</p>
                }
                <label htmlFor={name} className='name'>{name.charAt(0).toUpperCase() + name.slice(1)}</label>
            </div>
            {touched[name] && errors[name] ? (
                <ErrorMessage name="name" component="div" className='error' onClick={resetForm}/>
            ) : (
                <Field name="name" type="text"
                       className={classNames('input', {'error': touched[name] && errors[name]})}/>
            )}
        </div>
    );
}

export default TextInput;