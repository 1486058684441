import React from 'react';
import './Template.scss'
import Navigation from '../../components/navigation/Navigation';

const Template = (props) => {
    return (
        <div className='template'>
            <Navigation/>
            <main className='main'>
                <div className='child'>
                    {props.children}
                </div>
            </main>
        </div>
    );

}
export default Template;
