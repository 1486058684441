import React from 'react';
import './PhotoInput.scss';

import Photo from '../photos/Photo';

const PhotoInput = ({photoId, onImageUpload}) => {
    return (
        <div className='photo-input'>
            <div className='label'>
                <label htmlFor="photo" className='name'>Photo</label>
            </div>
            <Photo photoId={photoId || null} onImageUpload={onImageUpload}/>
        </div>
    );
};

export default PhotoInput;