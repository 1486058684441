import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import './Ingredients.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCarrot} from '@fortawesome/free-solid-svg-icons';

import * as actions from './IngredientsActions';
import ItemTable from "../../components/tables/ItemTable";
import ItemModal from "../../components/modals/ItemModal";
import Button from "../../components/buttons/Button";
import Options from '../../components/buttons/Options';

const Ingredients = () => {
    const [ingredients, setIngredients] = useState([]);
    const [ingredient, setIngredient] = useState({
        id: null,
        name: "",
        unit: ""
    });

    useEffect(() => {
        actions.loadIngredients(setIngredients);
    }, []);

    const handleRemoveIngredient = (id) => {
        actions.deleteIngredient(id, () => {
            setIngredients(ingredients.filter(ingredient => ingredient.id !== id))
            setIngredient({id: null, name: "", unit: ""})
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Ingredient",
                accessor: "name",
                Cell: ({row}) => (
                    <div className='name-cell'>
                        <img className='img'
                             src={row.original.image.image_url ? row.original.image.image_url : "/icon_empty.png"}
                             alt='item'/>
                        <p className='name'>{row.original.name}</p>
                    </div>
                ),
            },
            {
                Header: "Unit",
                accessor: "unit",
                Cell: ({row}) => (
                    <div className='unit-cell'>
                        <p className='name'>{row.original.unit}</p>
                    </div>
                ),
            },
            {
                Header: 'Options',
                accessor: 'options',
                Cell: ({row}) => (
                    <Options item={row.original} link="ingredient" deleteItem={(i) => setIngredient(i)} />
                ),
            },
        ],
        [ingredients]
    );

    return (
        <div className='ingredients'>
            <header className='header'>
                <div className='title'>
                    <FontAwesomeIcon icon={faCarrot} className='icon'/>
                    <h1>Ingredients</h1>
                </div>
                <Link to='/ingredient'>
                    <Button variant="primary" name="+ Add new"/>
                </Link>
            </header>
            <ItemTable items={ingredients} columns={columns}/>
            <ItemModal item={ingredient} type="ingredient" onRemoveItem={handleRemoveIngredient}
                       onClose={() => setIngredient({id: null, name: "", unit: ""})}/>
        </div>
    );
}

export default Ingredients;