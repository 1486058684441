import React from 'react';
import {useTable} from 'react-table';

import './ItemTable.scss'


const ItemTable = ({items, columns}) => {

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data: items});

    return (
        <table {...getTableProps()} className='table'>
            <thead className='thead'>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                </tr>
            ))}
            </thead>

            <tbody {...getTableBodyProps()} className='tbody'>
            {rows.map((row, i) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
}

export default ItemTable;